// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-menu-principal-js": () => import("./../../../src/pages/menu-principal.js" /* webpackChunkName: "component---src-pages-menu-principal-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-sugerencias-js": () => import("./../../../src/pages/sugerencias.js" /* webpackChunkName: "component---src-pages-sugerencias-js" */),
  "component---src-pages-tragos-js": () => import("./../../../src/pages/tragos.js" /* webpackChunkName: "component---src-pages-tragos-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-vinos-js": () => import("./../../../src/pages/vinos.js" /* webpackChunkName: "component---src-pages-vinos-js" */)
}

